<template>
    <div class="SignupDetails">
        <h1>{{$t('community_config_title_signup')}}</h1>
        <Separator/>  
        <div class="SignupDetails--Content">
          <div class="SignupDetails--SwitchGroup">
            <div class="SignupDetails--Switch">
              <i v-on:click="openModalSignup()" class="fas fa-info"></i>
              <label>{{$t('community_config_signup_active')}}</label>
              <SwitchInput class="SwitchInput--Small" v-model="data.allowRegistration" />
            </div>
            <div class="SignupDetails--Switch" v-if="data.allowRegistration">
              <i v-on:click="openModalAfterLogin()" class="fas fa-info"></i>
              <label>{{$t('community_config_signup_afterlogin')}}</label>
              <SwitchInput class="SwitchInput--Small" v-model="data.allowAfterLogin" />
            </div>
        </div>
        <div class="SignupDetails--SignupUrl" v-if="data.allowRegistration">
            <label>{{$t('community_signup_url')}}</label>
            <a target="_blank" :href="data.url" v-if="data.url.slice(-9) != 'undefined'" v-on:click="copyUrl($event)"><i class="fas fa-link"></i>{{data.url}}</a>
            <div class="SignupDetails--UrlCopied">
              <i class="fas fa-clipboard-check"></i>
              {{ $t('videochat_url_copied') }}
            </div>
        </div>
        <div>
          <label>{{$t('community_config_signup_acceptance')}}</label>
          <span v-on:click="$emit('open-image','SIGNUP_ACCEPT')">{{ $t('community_config_see_example') }}</span>
          <CommunityInput type="textarea" :required="true" v-model="data.acceptance"/>
        </div>
        <div>
            <label>{{$t('community_config_signup_terms')}}</label>
            <span v-on:click="$emit('open-image','TERMS')">{{ $t('community_config_see_example') }}</span>
            <vue-editor class="SignupDetails--Editor" :placeholder="$t('community_config_signup_terms_placeholder')" v-model="data.conditions" :editorToolbar="customToolbar"></vue-editor>
        </div>
        </div>
    </div>
  </template>
  
  <script>
  import { VueEditor } from "vue3-editor";
  import Swal from 'sweetalert2'
  import SwitchInput from "~/components/SwitchInput/SwitchInput"
  import Separator from '@/components/Separator.vue';
  import CommunityInput from '@/components/CommunityInput'

    export default {
      name: 'SignupDetails',
      components:{
        VueEditor,
        SwitchInput,
        Separator,
        CommunityInput
      },
      props:{
        signUpData:{
          type:Object,
          default: () => {}
        }
      },
      data(){
        return{
          data:{},
          customToolbar: [
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ["bold", "italic", "underline", "strike"],
            [{ header: 1 }, { header: 2 }],
            ["blockquote", "code-block"],
            [{ list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ color: [] }, { background: [] }],
            ["link"],
          ],
        }
      },
      methods:{
        copyUrl(event){
          event.preventDefault()
          navigator.clipboard.writeText(this.data.url);
          $(".SignupDetails--UrlCopied").show()
          setTimeout(() => {
            $(".SignupDetails--UrlCopied").hide()
          }, 2000)
        },
        openModalSignup(){
          Swal.fire({
            title: 'Activar registro',
            html:`Si se activa esta opción se podrán registrar todos aquellos usuarios que lo deseen desde la página de registro en <a target='_blank' href='${this.data.url}'>${this.data.url}</a>.`,
            icon: 'info',
            showCloseButton: false,
            showCancelButton: false,
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i>',
          })
        },
        openModalAfterLogin(){
          Swal.fire({
            title: 'Activar registro ampliado',
            html:`Si se activa esta opción, además del Nombre, Apellidos e Email se solicitarán otros datos adicionales como nickname, foto, género, fecha de nacimiento y provincia a los usuarios que se deseen registrar.`,
            icon: 'info',
            showCloseButton: false,
            showCancelButton: false,
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i>',
          })
        },
        getData(){
          const data = this.data
          data.afterLoginBypass = !data.allowAfterLogin
          return data
        }
      },
      mounted(){
        this.data = {
          ...this.signUpData,
          afterLoginBypass: this.signUpData.afterLoginBypass,
          allowAfterLogin: this.signUpData.allowAfterLogin
        }
      }
    }
  </script>

<style lang="scss">
      .SignupDetails--Editor #quill-container{
        height: 300px;
      }
</style>
  <style scoped lang="scss">
    .SignupDetails{
      font-family: 'Roboto Condensed';
      h1{
        font-weight: bold;
        font-size: 22px;
        letter-spacing: 0.01em;
        color: #3E3E3E;
        margin-bottom: 16px;
      }
      &--SignupUrl{
        display: flex;
        flex-direction: column;
        position: relative;
        a{
          color: #969696;
          @media only screen and (max-width: 600px) {
            overflow-wrap:anywhere;
  }
        }
        i{
          margin-right: 5px;
          color: var(--primary-color);
        }
      }
      &--UrlCopied{
        display: none;
        position: absolute;
        border: 1px solid #F7F4F8;
        box-shadow: 3px 3px 22px rgba(0, 0, 0, 0.15);
        padding: 6px 10px;
        font-size:14px;
        background: white;
        font-family: Lato;
        width: fit-content;
        top: 25%;
        right: 50%;
        i{
          font-size: 20px;
        }
      }
      &--Switch{
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: normal;
        font-size: 18px;
        color: #3E3E3E;
        i{
          color: #004085;
          background-color: #cce5ff;
          padding: 3px 6px;
          border-radius: 50%;
          font-size: 11px;
          cursor: pointer;
          border: 1px solid #b8daff;
        }
      }
      &--SwitchGroup{
        display: flex;
        flex-wrap: wrap;
        gap: 50px;
      }
      &--Content{
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      label{
        font-weight: normal;
        font-size: 18px;
        color: #3E3E3E;
      }
      span{
        margin-left: 5px;
        color: var(--primary-color);
        cursor: pointer;
      }
    }
  </style>  