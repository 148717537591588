
import { defineComponent } from 'vue';
export default defineComponent({
  name : 'CardInput',
  props:{
    type:{
      type:String,
      default:"text"
    },
    placeholder:{
      type:String,
      default:" "
    },
    label:{
      type: String,
      default: null
    },
    text:{
      type: String,
      default: null
    },
    isTextarea:{
      type: Boolean,
      default: false
    },
    modelValue:[String, Number],
  },
  emits: ['update:modelValue', 'blur', 'updateAfter1000Ms'],
  watch: {
    value (newValue) {
     this.$emit('update:modelValue', newValue)
   }
  },
  computed: {
    value: {
      get():any {
        return this.modelValue
      },
      set(newValue: any) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
})
