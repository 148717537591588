<template>
    <div class="WelcomeDetails">
        <h1>{{$t('welcome')}}</h1>
        <Separator/>
        <div class="WelcomeDetails--Content">
          <ImageSelector 
                v-model="data.moderatorImage"
                :title="$t('community_config_welcome_mod_image')"
                :text="$t('community_config_welcome_mod_image_explanation')"
                :publicLogo=false />
          <div class="WelcomeDetails--LoginUrl">
            <label>{{$t('community_login_url')}}</label>
              <a target="_blank" :href="url" v-on:click="copyUrl($event)"><i class="fas fa-link"></i>{{url}}</a>
            <div class="WelcomeDetails--UrlCopied">
              <i class="fas fa-clipboard-check"></i>
              {{ $t('videochat_url_copied') }}
            </div>
          </div>
          <div>
            <label>{{$t('community_config_welcome_title')}}</label>
            <span v-on:click="$emit('open-image','WELCOME_TITLE')">{{ $t('community_config_see_example') }}</span>
            <CommunityInput type="text" v-model="data.loginWelcomeTextBlack" :placeholder="$t('login_welcome_text')"/>
          </div>
          <div>
            <label>{{$t('community_config_welcome_name')}}</label>
            <span v-on:click="$emit('open-image','WELCOME_NAME')">{{ $t('community_config_see_example') }}</span>
            <CommunityInput type="text" v-model="data.loginWelcomeTextColor" :placeholder="('WAT Lab')"/>
          </div>
          <div>
            <label>{{$t('community_config_welcome_mod_info')}}</label>
            <span v-on:click="$emit('open-image','MODERATOR')">{{ $t('community_config_see_example') }}</span>
            <CommunityInput type="textarea" :required="true" v-model="data.moderatorInfo" :placeholder="$t('welcome_moderator_info')"/>
          </div>
          <div>
            <label>{{$t('community_config_welcome_desc')}}</label>
            <span v-on:click="$emit('open-image','COMMUNITY')">{{ $t('community_config_see_example') }}</span>
            <vue-editor :placeholder="$t('welcome_instructions')" v-model="data.instructions" :editorToolbar="customToolbar"></vue-editor>
          </div>
          <div class="WelcomeDetails--Media">
            <label>{{ $t("images") }}</label>
            <div class="WelcomeDetails--MediaWrapper">
              <Dropzone
                ref="imageUploader"
                class="WelcomeDetails--Uploader"
                :uploadUrl="fileUploadPath"
                :uploadHeaders="uploadHeaders"
                :uploadText="getSVG()"
                :acceptedFiles="'image/jpg,image/jpeg,image/png'"
                :maxFiles="1"
                v-on:file-uploaded="imageUploadSuccess"
              />
              <div class="WelcomeDetails--MediaItem" v-for="img in data.images">
                <img :src="img.path" />
                <i class="fas fa-trash-alt" v-on:click="imageDelete(img)"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="WelcomeDetails--Media">
          <label>{{ $t("videos") }}</label>
          <div class="WelcomeDetails--MediaWrapper">
            <Dropzone
              ref="videoUploader"
              class="WelcomeDetails--Uploader"
              :uploadUrl="videoUploadPath"
              :uploadHeaders="uploadHeaders"
              :uploadText="getSVG()"
              :acceptedFiles="'video/*'"
              :maxFiles="1"
              v-on:file-uploaded="videoUploadSuccess"
            />
            <div class="WelcomeDetails--MediaItem" v-for="(video, index) in data.videos" :key="video.path">
              <video>
                <source :src="video.path" type="video/mp4" />
                <source :src="video.path" type="video/ogg" />
              </video>
              <i class="fas fa-trash-alt" v-on:click="removeVideo(video, index)"></i>
            </div>
          </div>
        </div>
    </div>
  </template>

  <script>
  import { VueEditor } from "vue3-editor";
  import ImageSelector from './ImageSelector'
  import CommunityInput from '@/components/CommunityInput'
  import Separator from '@/components/Separator.vue';
  import Dropzone from "~/components/Dropzone";
  import ApiService from "~/services/api.service";
  import { mapGetters } from 'vuex'
  export default {
      name: 'WelcomeDetails',
      components:{
        Separator,
        CommunityInput,
        ImageSelector,
        VueEditor,
        Dropzone
      },
      computed:{
        ...mapGetters(['communityInfo'])
      },
      props:{
        welcomeInfo:{
          type:Object,
          default: ()=>{}
        }
      },
      data(){
        return{
          fileUploadPath: process.env.VUE_APP_API_URL + "/experienceLabs/file",
          videoUploadPath: process.env.VUE_APP_API_URL + "/experienceLabs/video",
          uploadHeaders: ApiService.getConfig().headers,
          imageAcceptedFiles: "image/jpg,image/jpeg,image/png",
          videoAcceptedFiles: "video/*",
          data:{},
          url:'',
          customToolbar: [
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ["bold", "italic", "underline", "strike"],
            [{ header: 1 }, { header: 2 }],
            ["blockquote", "code-block"],
            [{ list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ color: [] }, { background: [] }],
            ["link"],
          ],
        }
      },
      methods:{
        imageDelete(file) {
          this.data.images = _.filter(this.data.images, function (o) {
            return o.path != file.path;
          });
        },
        imageUploadSuccess: function (file, response) {
          this.data.images.push({ path: response.path, uuid: file.uuid });
          this.$refs.imageUploader.reset();
        },
        removeVideo(file, index) {
          this.data.videos.splice(index, 1);
        },
        videoUploadSuccess: function (file, response) {
          this.data.videos.push({ path: response.path, uuid: file.uuid });
          this.$refs.videoUploader.reset();
        },
        getSVG() {
          return "<svg width='26' height='24' viewBox='0 0 26 24' fill='none' xmlns='http://www.w3.org/2000/svg'><g clip-path='url(#clip0)'><path d='M24.914 11.5128H13.3934V0.399217C13.3934 0.187581 13.2155 0.0159912 12.9961 0.0159912C12.7767 0.0159912 12.5988 0.187581 12.5988 0.399217V11.5128H1.07817C0.858783 11.5128 0.680908 11.6844 0.680908 11.896C0.680908 12.1076 0.858783 12.2792 1.07817 12.2792H12.5988V23.3928C12.5988 23.6044 12.7767 23.776 12.9961 23.776C13.2155 23.776 13.3934 23.6044 13.3934 23.3928V12.2792H24.914C25.1334 12.2792 25.3113 12.1076 25.3113 11.896C25.3113 11.6844 25.1334 11.5128 24.914 11.5128Z' fill='#828282'/></g><defs><clipPath id='clip0'><rect width='24.6304' height='23.76' fill='white' transform='translate(0.680908 0.0159912)'/></clipPath></defs></svg>";
        },
        copyUrl(event){
          event.preventDefault()
          navigator.clipboard.writeText(this.url);
          $(".WelcomeDetails--UrlCopied").show()
          setTimeout(() => {
            $(".WelcomeDetails--UrlCopied").hide()
          }, 2000)
        }
      },
      mounted(){
        this.data = this.welcomeInfo
        this.url = location.origin+'/c/'+this.communityInfo.urlToken+'/login'
      }
    }
  </script>
  
  <style scoped lang="scss">
    .WelcomeDetails{
        font-family: 'Roboto Condensed';
        label{
          font-size: 18px;
          color: #3E3E3E;
        }
        h1{
            font-weight: bold;
            font-size: 22px;
            letter-spacing: 0.01em;
            color: #3E3E3E;
            margin-bottom: 16px;
        }   
        &--Content{
          display: flex;
          flex-direction: column;
          gap: 20px;
          span{
            margin-left: 5px;
            color: var(--primary-color);
            cursor: pointer;
          }
        }
        &--Media {
          margin-top: 15px;
          label {
            text-transform: capitalize;
          }
        }
        &--Uploader {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f5f5f5;
          border: 1px solid #d3d3d3;
          color: #828282;
          width: 150px;
          height: 95px;
          margin: 0 10px 10px 0;
        }
        &--LoginUrl{
          display: flex;
          flex-direction: column;
          position: relative;
          a{
            color: #969696;
          }
          i{
            margin-right: 5px;
            color: var(--primary-color);
          }
        }
        &--UrlCopied{
          display: none;
          position: absolute;
          border: 1px solid #F7F4F8;
          box-shadow: 3px 3px 22px rgba(0, 0, 0, 0.15);
          padding: 6px 10px;
          font-size:14px;
          background: white;
          font-family: Lato;
          width: fit-content;
          top: 25%;
          right: 50%;
          i{
            font-size: 20px;
          }
        }
        &--MediaWrapper{
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }
        &--MediaItem {
          width: 150px;
          height: 95px;
          position: relative;
          background: #f5f5f5;
          border: 1px solid #d3d3d3;
          img {
            width: 100%;
            height: 100%;
          }
          video {
            width: 100%;
            height: 61px;
          }
          div {
            background: rgba(0, 0, 0, 0.5);
            height: 100%;
            padding: 10px;

            span {
              font-size: 0.8em;
              word-break: break-all;
              color: white;
            }
          }
          input {
            background: #f5f5f5;
            border: none;
            border-top: 1px solid #d3d3d3;
            height: initial;
            font-size: 15px;
            padding: 4px;
          }
          i {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
            color: #721c24;
          }
        }
    }
  </style>  