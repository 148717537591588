<template>
  <div class="ActivityBox" :class="{'disabled':disabled}">
    <ActivityImage :type="activity.type"/>
    <div class="ActivityBox__Data">
      <div class="ActivityBox__Title">
        {{activity.title}}
      </div>
      <div class="ActivityBox__Dates">
        {{$t('date_from_to2',{from:formatDateForTranslation(activity.dateFrom), to:formatDateForTranslation(activity.dateTo)})}}
      </div>
    </div>
    <div class="ActivityBox__Check">
      <input :disabled="disabled" class="checkbox" type="checkbox" @click="clickedActivity( $event )" v-model="checked"/>
    </div>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import ActivityImage from '@/components/Activity/ActivityImage.vue';
export default {
  data() {
    return {
      checked: false
    }
  },
  props: {
    activity : {},
    active: Boolean,
    disabled: Boolean
  },
  components: {
    ActivityImage
  },
  methods:{
    formatDateForTranslation(date){
      if (date == undefined) {
        return "-"
      }
      return moment(date).format("DD/MM HH:mm");
    },
    clickedActivity ( event ) {
      if (this.checked){
          this.$emit( 'unchecked', event );
      } else{
          this.$emit( 'checked', event );
      }

    }
  },
  async mounted(){
    this.checked = this.active;
  }
}
</script>
<style scoped lang="scss">
.ActivityBox{
  background-color: #F5F5F5;
  border: 1px solid #E1E1E1;
  margin-bottom: 10px;
  padding:10px;
  display: flex;
  width:100%;
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: none;
    input::before{
          cursor: not-allowed;
    }
  }
  &__Title{
    font-weight: bold;
  }
  &__Data{
    width: 85%;
  }
  &__Dates{
    font-size: .9em;
  }
  &__Check{
    //align-self: flex-end;
    display: flex;
    align-items: center;
    input{
      position:relative;
      margin-right: 5px;
      outline: none;
    }
    input::before{
      height:25px;
      width: 25px;
      border:1px solid var(--primary-color);
      color: transparent;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size:24px;
      content:'\f00c';
      position: absolute;
      background: white;
      cursor:pointer;
      margin-top:-5px;
    }
    input:checked::before{
      color: var(--primary-color);
    }

  }
}
</style>
