<template>
    <div class="Branding">
        <h1>{{$t('community_config_title_branding')}}</h1>
        <Separator/>
        <div class="Branding--Group">
            <ColorSelector 
                v-model="data.mainColor"
                :text="$t('profile_primary_color_info')"
                :title="$t('profile_primary_color')">
                <span class="SeeExample" v-on:click="$emit('open-image','THEME_COLOR')">{{ $t('community_config_see_example') }}</span>
            </ColorSelector>
            <ColorSelector 
                v-model="data.textColor"
                :text="$t('profile_text_color_info')"
                :title="$t('profile_text_color')">
            </ColorSelector>
            <ColorSelector 
                v-model="data.buttonColor"
                :text="$t('profile_button_color_info')"
                :title="$t('profile_button_color')">
                <span class="SeeExample" v-on:click="$emit('open-image','BUTTONS_COLOR')">{{ $t('community_config_see_example') }}</span>
            </ColorSelector>
        </div>
        <div class="Branding--Group">
            <ImageSelector 
                v-model="data.loginImageUrl"
                :title="$t('profile_primary_image')"
                :text="$t('form_image_min_size_recommended',{size:'500x600'})"
                :publicLogo=false>
                <span class="SeeExample" v-on:click="$emit('open-image','IMG_THEME')">{{ $t('community_config_see_example') }}</span>
            </ImageSelector>
            <ImageSelector 
                v-model="data.logoUrl"
                :title="$t('profile_superimposed_image')"
                :text="$t('profile_superimposed_image_info')"
                :publicLogo=true>
                <select v-model="data.loginLogoPosition">
                    <option value="FLEX-START">{{$t('community_personalization_main_logo_position_top')}}</option>
                    <option value="CENTER">{{$t('community_personalization_main_logo_position_center')}}</option>
                    <option value="FLEX-END">{{$t('community_personalization_main_logo_position_bottom')}}</option>
                </select>
                <span class="SeeExample" v-on:click="$emit('open-image','SUPER_LOGO')">{{ $t('community_config_see_example') }}</span>
            </ImageSelector>
            <ImageSelector 
                v-model="data.moderatorLogo"
                :title="$t('profile_moderator_logo')"
                :text="$t('form_image_min_size_recommended', {size:'250x250'})"
                :publicLogo=false>
                <span class="SeeExample" v-on:click="$emit('open-image','MODERATOR_LOGO')">{{ $t('community_config_see_example') }}</span>
            </ImageSelector>
            <ImageSelector 
                v-model="data.favicon"
                :title="$t('community_personalization_favicon')"
                :text="$t('profile_favicon_info')"
                :publicLogo=false>
                <span class="SeeExample" v-on:click="$emit('open-image','FAVICON')">{{ $t('community_config_see_example') }}</span>
            </ImageSelector>
        </div>
        <div class="Branding--Texts">
            <label>{{$t('community_personalization_login_text')}}</label>
            <span v-on:click="$emit('open-image','LOGIN')">{{ $t('community_config_see_example') }}</span>
            <CommunityInput type="textarea" :required="true" v-model="data.loginText" :placeholder="$t('profile_signup_explanation')"/>
            <label>{{$t('community_personalization_signup_text')}}</label>
            <span v-on:click="$emit('open-image','SIGNUP')">{{ $t('community_config_see_example') }}</span>
            <CommunityInput type="textarea" :required="true" v-model="data.signupText" :placeholder="$t('profile_login_explanation')"/>
            <label>{{$t('community_personalization_incentive_text')}}</label>
            <span v-on:click="$emit('open-image','EMAIL')">{{ $t('community_config_see_example') }}</span>
            <CommunityInput type="textarea" :required="true" v-model="data.motivationText" :placeholder="$t('placeholder_write_text')"/>
        </div>
    </div>
 </template>
  
  <script>
    import { mapGetters } from 'vuex'
    import ColorSelector from './ColorSelector'
    import ImageSelector from './ImageSelector'
    import CommunityInput from '@/components/CommunityInput'
    import Separator from '@/components/Separator.vue';
    export default {
      name: 'Branding',
      components:{
        ColorSelector,
        ImageSelector,
        CommunityInput,
        Separator
      },
      props:{
        personalization:{
            type:Object,
            default: () => {}
        }
      },
      watch:{
        personalization(newVal){
            this.data = newVal
        }
      },
      data(){
        return{
            data:{}
        }
      },
      mounted(){
        this.data = this.personalization
      }
    }
  </script>
  <style lang="scss">
    .Branding .CommunityInput{
        margin-bottom: 20px;
    }
  </style>
  <style scoped lang="scss">
    .Branding{
        h1{
            font-weight: bold;
            font-size: 22px;
            letter-spacing: 0.01em;
            color: #3E3E3E;
            margin-bottom: 16px;
        }
        &--Group{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 20px;
            margin-bottom: 20px;
        }
        &--Texts{
            span{
                margin-left: 5px;
                color: var(--primary-color-text);
                cursor: pointer;
            }
            label{
                font-weight: normal;
                font-size: 18px;
                color: #3E3E3E;
            }
        }
        .SeeExample{
            color: var(--primary-color-text);
            cursor: pointer;
            display: flex;
            flex-direction: column;
        }
    }
  </style>
  