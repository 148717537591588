<template>
    <div class="ClientReplyToEmail">
      <h1>{{ $t('community_config_client_reply_to') }}</h1>
      <div class="ClientReplyToEmail--Content">
        <div>
          <label>{{ $t('community_config_client_email') }}</label>
          <CommunityInput
            type="email"
            v-model="localClientEmail"
            placeholder="example@example.com"
            :required="true"
            @input="onEmailInput"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CommunityInput from '@/components/CommunityInput'  
  export default {
    name: 'ClientReplyToEmail',
    components: {
      CommunityInput,
    },
    props: {
      clientEmail: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        localClientEmail: this.clientEmail,
      };
    },
    watch: {
      clientEmail(newEmail) {
        this.localClientEmail = newEmail;  
      },
    },
    methods: {
      onEmailInput() {
        this.$emit('update-email', this.localClientEmail);  
      },
    },
  }
  </script>

<style scoped lang="scss">
.ClientReplyToEmail {
    font-family: 'Roboto Condensed';

    label {
        font-size: 18px;
        color: #3E3E3E;
    }

    h1 {
        font-weight: bold;
        font-size: 22px;
        letter-spacing: 0.01em;
        color: #3E3E3E;
        margin-bottom: 16px;
    }

    &--Content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        span {
            margin-left: 5px;
            color: var(--primary-color);
            cursor: pointer;
        }
    }

    &--Media {
        margin-top: 15px;

        label {
            text-transform: capitalize;
        }
    }

    &--Uploader {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f5f5;
        border: 1px solid #d3d3d3;
        color: #828282;
        width: 150px;
        height: 95px;
        margin: 0 10px 10px 0;
    }

    &--LoginUrl {
        display: flex;
        flex-direction: column;
        position: relative;

        a {
            color: #969696;
        }

        i {
            margin-right: 5px;
            color: var(--primary-color);
        }
    }

    &--UrlCopied {
        display: none;
        position: absolute;
        border: 1px solid #F7F4F8;
        box-shadow: 3px 3px 22px rgba(0, 0, 0, 0.15);
        padding: 6px 10px;
        font-size: 14px;
        background: white;
        font-family: Lato;
        width: fit-content;
        top: 25%;
        right: 50%;

        i {
            font-size: 20px;
        }
    }

    &--MediaWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    &--MediaItem {
        width: 150px;
        height: 95px;
        position: relative;
        background: #f5f5f5;
        border: 1px solid #d3d3d3;

        img {
            width: 100%;
            height: 100%;
        }

        video {
            width: 100%;
            height: 61px;
        }

        div {
            background: rgba(0, 0, 0, 0.5);
            height: 100%;
            padding: 10px;

            span {
                font-size: 0.8em;
                word-break: break-all;
                color: white;
            }
        }

        input {
            background: #f5f5f5;
            border: none;
            border-top: 1px solid #d3d3d3;
            height: initial;
            font-size: 15px;
            padding: 4px;
        }

        i {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
            color: #721c24;
        }
    }
}



</style>